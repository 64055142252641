// plugins/auto-logout.js

export default ({ store, app, query,params, redirect }) => {
    let logoutTimer;

    // var timer = process.env.LOGOUT_TIMEOUT

    var timer = 900000

    console.log("timer@@@@", timer);
    
    var isloginSucess = store.state.sessionStorage.login_successfull;
    if (isloginSucess) {
        var encrypt = query.encrypted ? query.encrypted : 'N'
        // Function to reset the timer
        const resetTimer = () => {
            if (logoutTimer) clearTimeout(logoutTimer);

            // Set the timer for 5 minutes (300000 ms)
            logoutTimer = setTimeout(() => {
                handleLogout();
                console.log("This message will appear after 15 minutes");
            }, 900000);
        };
    
    console.log("resetTimer@@", resetTimer);
    

        // Function to handle logout logic
        const handleLogout = () => {
            console.log("encrypt@@@@", encrypt);
            
            if (encrypt === 'y') {
                    store.commit("sessionStorage/assignuserLoginSuccessfull", false);
                    store.commit("sessionStorage/assignLogin", false); 
                    console.log("params",params);
                    
                    if (!params.id && !params.type) {
                         var customerid = store.state.sessionStorage.params_customer_id
                    window.location.replace(`/${customerid}/shop/sidebar/list?lang=en&encrypted=y`)
                    }
                   
                
            } else {
                store.commit("sessionStorage/assignuserLoginSuccessfull", false);
                store.commit("sessionStorage/assignLogin", false);

                window.location.replace('/')
            }
            console.log("isloginSucess@@", isloginSucess);

        };

        // Listen for user activity and reset timer
        document.addEventListener('mousemove', resetTimer);
        document.addEventListener('keydown', resetTimer);

        // Initialize the timer on page load
        resetTimer();
    } else {
        console.log("isloginSucess@@", isloginSucess);
}

};
